@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

a {
  text-decoration: none !important;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
  background: #f4f4f4 !important;
}

html {
  --scrollbarBG: rgb(206, 206, 206);
  --thumbBG: #4b4b4b;
  --FoxYellow: #F7CE40;
  --FoxBlack: #000000;
  --White: #FFFFFF;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

  /* ------ Form Inputs ID's ------- */
  #fName,
  #lName,
  #empId,
  #venue,
  #password,
  #mobile,
  #workEmail,
  #empDesgn,
  #experience,
  #resourceType,
  #gender,
  #department,
  #skills{
    scroll-margin-top: 50px !important;
  }

  @media (max-width: 640px) {
     input[type="date"]:before {
        /* content: 'dd-mm-yyyy' !important; */
    }

     input[type="date"]:focus:before {
        /* content: '' !important; */
    }

     input[type="date"] {
        display: flex !important;
    }
}


/* ----------- Global Buttons & Err Msg Declaration ------------ */
.errMsg{
  color: red !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin: 3px 0px 0px 5px !important;
  text-align: start !important;
}

.InputLabel{
  color: #6f6f6f;
  font-weight: 500;
  margin: 0;
}

.loginBtn{
  color: #FFFFFF !important;
  background: #000000 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1) !important;
  outline: none !important;
}

.activeBtn{
  color: #FFFFFF !important;
  background: #000000 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1) !important;
  outline: none !important;
}


.activeBtn:after {
  border-radius: 6px !important;
  position: absolute !important;
  content: "" !important;
  width: 0 !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  direction: rtl !important;
  z-index: -1 !important;
  box-shadow:
   -7px -7px 20px 0px rgba(228, 228, 228, 0.1),
   -4px -4px 5px 0px rgba(228, 227, 227, 0.1),
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001 !important;
  transition: all 0.3s ease !important;
}

.activeBtn:hover:after {
  left: auto !important;
  right: 0 !important;
  width: 100% !important;
}

.activeBtn:active {
  top: 2px !important;
}

.activeBtn:hover{
  color: #000000 !important;
  background: #FFFFFF !important;
  border: none !important;
  border-radius:6px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.secondaryBtn{
  color: #000000 !important;
  background: transparent !important;
  border: 1px solid #F7CE40 !important;
  border-radius:6px !important;
  transition: 0.3s !important;
  padding: 5px 11px !important;
}

.secondaryBtn:hover{
  color: #000000 !important;
  background: transparent !important;
  border: 1px solid #F7CE40 !important;
  border-radius:6px !important;
}
.primaryBtn{
  color: #000 !important;
  background: #FFFFFF !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1) !important;
  outline: none !important;
}


.primaryBtn:after {
  border-radius: 6px !important;
  position: absolute !important;
  content: "" !important;
  width: 0 !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  direction: rtl !important;
  z-index: -1 !important;
  box-shadow:
   -7px -7px 20px 0px rgba(228, 228, 228, 0.1),
   -4px -4px 5px 0px rgba(228, 227, 227, 0.1),
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001 !important;
  transition: all 0.3s ease !important;
}

.primaryBtn:hover:after {
  left: auto !important;
  right: 0 !important;
  width: 100% !important;
}

.primaryBtn:active {
  top: 2px !important;
}

.primaryBtn:hover{
  color: #FFFFFF !important;
  background: #000000 !important;
  border: none !important;
  border-radius:6px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.LogoutBtn{
  color: #F7CE40 !important;
  background: transparent !important;
  width: 100% !important;
  border: none !important;
  border-radius:6px !important;
  transition: 0.3s !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: 0.3s !important;
}

.LogoutBtn:hover{
  color: #F7CE40 !important;
  background: transparent !important;
  width: 100% !important;
  border: none !important;
  border-radius:6px !important;
 transform: translateX(-10px) !important;
}

.CancelBtn{
  color: #000000 !important;
  border: 1px solid #F7CE40 !important;
  background: transparent !important;
  border-radius: 6px !important;
  transition: 0.3s !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 5px 11px !important;
}

.accordionNav{
  border: 1px solid var(--FoxBlack) !important;
  color: var(--FoxBlack) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  background: transparent !important;
}

.accordionNavActive{
  border: 1px solid transparent !important;
  color: var(--White) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  background: var(--FoxBlack) !important;
}


.pagination{
  width: 100% !important;
}

.pagination .prev,
.pagination .prev:focus,
.pagination .next,
.pagination .next:focus{
  background: transparent !important;
  border: none !important;
  color: var(--FoxBlack) !important;
  transition: 0.3s !important;
}

.pagination .prev:hover{
  background: transparent;
  border: none;
  color: var(--FoxBlack);
  transform: translateX(-5px);
} 

.pagination .next:hover{
  background: transparent;
  border: none;
  color: var(--FoxBlack);
  transform: translateX(5px);
} 

.pagination .activePageButton{
  border: 1px solid var(--FoxBlack) !important;
  color: var(--FoxYellow) !important;
  background: var(--FoxBlack ) !important;
  transition: 0.1s !important;
  font-weight: 600;
}

.pagination .pageButton{
  border: 1px solid transparent !important;
  color: var(--FoxBlack) !important;
  background: transparent !important;
  transition: 0.1s !important;
}

.pagination select.form-select{
  border: 1px solid #6f6f6f;
  transition: 0.3s;
}

.modal .modal-content {
  border: none !important;
  border-radius: 12px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.modal .modal-content .modal-header{
  background: var(--FoxYellow);
  padding: 10px 16px;
  font-weight: 600;
}

.modal .modal-content .modal-header h5{
  margin: 0px;
  font-weight: 600;
  color: var(--FoxBlack);
}

.modal label{
  font-size: 15px !important;
  color: #575757;
  font-weight: 600 !important;
  margin: 0px 0px 4px !important;
}

.modal .modal-footer{
  background: #ebebeb !important;
}

.profile h2{
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0;
}

.profile .card{
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
  inset 0 0 3px rgba(0, 0, 0, 0.2) !important;
}

.profile .card div{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.profile .card h6{
  margin: 0px;
}

.profile .card p{
  margin-left: 6px;
  margin-bottom: 0px;
}
/* ----------- LOGIN ------------ */

.login,
.Register{
  height: 100vh;
  width: 100vw;
}

.login input.form-control:focus,
.Register input.form-control:focus{
  border: 1px solid #6f6f6f;
  outline: none;
  box-shadow: none;
}

.login .container-fluid{
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .parent{
  height: 70vh;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.login .left{
  background:var(--White);
  border-radius: 12px 0px 0px 12px;
}

.Register .card{
  border: none;
  border-radius:12px;
  padding: 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Register .left{
  background: var(--FoxBlack);
  border-radius: 12px 0px 0px 12px;
  padding: 14px;
}

.Register .card h6{
  margin-bottom: 4px;
  font-weight: 600;
  color: var(--FoxYellow);
  display: flex;
  align-items: center;
}

.Register .card h3{
  margin-bottom: 12px;
  font-weight: 600;
  color: var(--FoxYellow);
}

.Register .card h4{
  margin-bottom: 6px;
  font-weight: 500;
  color: var(--White);
}

.Register .card p {
  color: var(--White);
  font-size: 14px;
} 

@media(max-width:768px){
  .login .parent{
    height: 100vh;
  }
  .login .parent{
    border-radius: 0px;
  }
  .login .right{
    border-radius: 0px !important;
  }
}


.login .left .subHeading{
  color:#656565;
  font-weight: 600;
}

 label.form-label{
 color: #575757 !important;
 font-size: 14px !important;
 margin-bottom:4px !important;
 font-weight: 600 !important;
}

label.foem-label span{
  color: red !important;
}

.login .right{
  background: #000000;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px 12px 12px 0px;
}

/* ------ Forgot Pass ------ */
.ForgotPass{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ForgotPass .card{
    border: none;
  border-radius:12px;
  padding: 0px 20px 25px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.ForgotPass .card h3{
  font-weight: 600;
  text-align: center;
  margin-bottom: 4px;
}
.ForgotPass .card h5{
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}
.ForgotPass .card p{
  font-size: 14px;
  color: #575757;
  text-align: center;
  margin-bottom: 8px;
}


nav {
  position: fixed;
  left: 40vh;
  right: 0vh;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  z-index: 1000;
}

nav h5 {
  color: black;
  font-weight: 600;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

@media (max-width: 992px) {
  .lg-nav {
    display: none;
  }

  .mobile-nav {
    position: fixed;
    --gap: 2rem;
    border-radius: 0px;
    inset: 0 0 0 20%;
    z-index: 1000;
    flex-direction: column;
    padding: 20% 2% 20% 0px;
    transform: translateX(100%);
    transition: 0.3s;
    list-style: none;
    margin: 0;
    background: #000000;
  }

  .mobile-nav li {
    margin-bottom: 15px;
    color: yellow;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .mobile-nav a.nav-link{
    border: 1px solid var(--FoxBlack);
    color: var(--FoxBlack);
    display: flex;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 15px;
    font-weight: 500;
 }
 
  .mobile-nav a.nav-link.active{
   background: var(--FoxBlack);
   border: 1px solid transparent;
   color: #ffffff;
   font-weight: 500;
 }

  .mobile-nav[data-visible="true"] {
    transform: translateX(0%);
  }

  .mobile-nav-toggle {
    margin:5px 0px 5px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    /* border: 3px solid #fff; */
  }
  .mobile-nav-toggle .menu-btn__burger {
    width: 35px;
    height: 3px;
    background: #F7CE40;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    z-index: 9999;
  }
  .mobile-nav-toggle .menu-btn__burger::before,
  .mobile-nav-toggle .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 36px;
    height: 3px;
    background: #F7CE40;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .mobile-nav-toggle .menu-btn__burger::before {
    transform: translateY(-10px);
  }

  .mobile-nav-toggle .menu-btn__burger::after {
    transform: translateY(10px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

}

/* ------ SideBar-lg CSS for Large or Desktop Devices ------ */
@media (min-width: 992px) {
  .lg-nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PopupData div{
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    margin-top: 5px;
  }

  .PopupData div p{
    margin-bottom: 0px;
  }

.mobile-nav {
    display: none;
  }

.mobile-nav-toggle {
    display: none;
  }

  .sidenav-lg::-webkit-scrollbar {
    width: 8px;
  }
  .sidenav-lg::-webkit-scrollbar-track {
    background: var(--FoxBlack);
  }
  .sidenav-lg::-webkit-scrollbar-thumb {
    background-color: var(--FoxYellow);
    border-radius: 6px;
  }

  .sidenav-lg {
    height: 100vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #000000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding-right: 8px;
    overflow-y: scroll;
    width: 40vh;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    scroll-behavior: smooth;
    --scrollbarBG: rgb(0, 0, 0);
    --thumbBG: #004686;
  }

  .sidenav-lg hr{
    border-width: 2px;
    color: #F7CE40;
    width: 40%;
    margin: auto;
    opacity: 1;
  }

  .sidenav-lg-parent {
    position: relative;
  }
 
  .outletDiv {
    position: absolute;
    left: 41vh;
    right: 2vh;
    top: 0vh;
  }
}

@media (max-width: 992px) {
  .outletDiv {
    position: absolute;
    left: 1vh;
    right: 1vh;
    top: 0vh;
  }

  nav{
    left: 0;
    right: 0;
  }
}

/* --- SideBar Accordion CSS for all sizes devices ----*/
.accordion{
  background-color: transparent !important;
}

.accordion .accordion-item{
  border: #000000;
}

.accordion .accrodion-body {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.accordion-item .accordion-header {
  background: #000000 !important;
  border: none !important;
  padding: 0px;
  box-shadow: none;
  color: #000000 !important;
  border: none !important;
}

.accordion .activeMenu .accordion-button {
  color: #000000 !important;
  background: linear-gradient(145deg, #f4c621, #F7CE40) !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  font-weight: 600 !important;
}

.accordion .dropDown{
  border-radius: 0px 0px 12px 0px !important;
  background: var(--FoxYellow);
}

.accordion .activeMenu.dropDown .accordion-button{
  border-radius: 0px 12px 0px 0px !important;
}

.accordion .accordion-button {
  background: transparent !important;
  color: #F7CE40 !important;
  border: none !important;
  font-weight: 400 !important;
  border-radius: 0px 12px 12px 0px !important;
  box-shadow: none !important;
  padding: 14px 20px;
}

.accordion .accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  /* color: #FFFFFF !important; */
  /* background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  color: #ffffff !important; */
}
/*
.accordion .accordion-button:not(.collapsed){
    background: linear-gradient(145deg, #1267cf, #073c7c) !important;
    color: #ffffff !important;
    font-weight: 500 !important;
} */

.accordion-item .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-item .accordion-button:focus:after {
  box-shadow: none;
  outline: #000000 !important;
  border: none !important;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  color: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .dropDown .accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  color: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .dropDown .accordion-button.collapsed:after {
  box-shadow: none;
  outline: #000000 !important;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  color: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion  label:nth-child(1){
  color: rgb(222, 219, 219) !important;
  padding: 1px 0px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin: 0px 0px 0px 0px !important;

}

.accordion  label{
  color: rgb(222, 219, 219) !important;
  padding: 1px 20px !important;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0px 0px 0px;

}


/* --------------------------------------------- Amployee dashboard CSS ----------------------------------------------------------- */

/* ----- Dash Cards CSS -----*/

.dashCards .card{
  border: none;
  border-radius: 12px;
  color: #000000;
  background: #FFFFFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  text-align: center;
  padding: 15px;
}

.dashCards .card div{
  background: #EECA4C;
  width: 28px;
  height: 27px;
  border-radius: 0px 0px 5px 5px;
  /* margin:auto; */
}

.dashCards .card h6{
  margin: 10px 0px 6px 0px;
}

.dashCards .card h3{
  margin: 0px;
  font-weight: 600;
}

/* ------------ Adding Artificial margin to every Oulets component's perent Div  ------------------- */ 

.outletPadding{
  margin-top: 70px;
}

.EmpDash label,
.noTask label{
  font-weight: 600;
  margin-bottom: 4px;
  color: #575757;
}

.EmpDash .AnalyticsReport{
  text-align: center;
  background: #F7CE40;
  color: #000000;
  padding: 5px 0px;
  border-radius: 10px;
}

.EmpDash .ChartCard{
  border-radius: 8px;
  border: none;
  color: #000000;
  text-align: center;
  box-shadow: rgba(22, 22, 22, 0.35) 0px 5px 15px;
}

/* --------VIEW TASK CSS ------------- */

.ViewTaskHeading{
  background: #dbd9d9 !important;
  text-align: center !important;
  padding: 5px !important;
}
.ViewTaskHeading h4{
  margin: 0px !important;
  color: #000 !important;
  font-weight: 600 !important;
}


/*  ---------------- Fixed header Responsive table CSS ---------------*/

.outer-wrapper {
  margin: 10px !important;
  padding: 10px !important;
  background: #FFFFFF !important;
  border: 1px solid rgb(224, 224, 224) !important;
  border-radius: 12px !important ;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2) !important;
  max-width: fit-content !important;
  max-height: fit-content !important;
}

.table-wrapper {
  overflow-y: scroll;
  overflow-x: scroll;
  height: fit-content;
  max-height: 66.4vh;
  margin-top: 5px;
  margin: 15px;
  padding-bottom: 20px;
}

.table-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  border-radius: 0px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.outer-wrapper table { 
  min-width: max-content;
  border-collapse: separate;
  border-spacing: 0px;    
}

.outer-wrapper table th{
  z-index: 1 !important;
  position: sticky; 
  top: 0px;
  background: #bdbdbd;
  color: var(--FoxBlack);
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  outline: 0.7px solid transparent;
  border: 1.5px solid transparent;
} 

.outer-wrapper table th, .outer-wrapper table td {
  padding: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.outer-wrapper table td {
  text-align: left;
  font-size: 12px;
  border: 0.5px solid rgb(205, 204, 204);
  padding-left: 10px;
}

/* for table width */
.taskwidth{
  width:200px;
}
.assignwidth{
  width:150px;
}
.assignwidth1{
  width:180px;

}

/* for error msg */
.requiredfield{
  color:red;
}

/* style for hr chart */
.chart-container{
  height:70vh;
  overflow: auto;
}
.chart-container::-webkit-scrollbar {
  width: 0.5em;  /* Set the width of the scrollbar */
}

.chart-container::-webkit-scrollbar-thumb {
  background-color:#f1f1f1;  /* Color of the scrollbar thumb */
  display: none;
}

.chart-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;  /* Color of the scrollbar track */
   display: none;
}

/* for select dropdown */
.custom-select {
  z-index: 1001; /* Set the desired z-index value */
}

/* for due days style */
.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: auto;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
}
.circle:hover {
    transform: scale(1.1); /* Apply scaling on hover */
}
.number {
    transform: translateY(-1px); /* Adjust the vertical alignment */
}
.green {
    background-color: yellow;
    color: black;
}

.orange {
    background-color: orange;
    color: black;
}

.red {
    background-color:#DB3546;
    color: white;
}
.max-width-th{
  width:200px;
  max-width: 200px;
}


/* css for pending completed rejected tab */
.pending-tab {
  background-color: #ffc107; /* Warning color for "Pending" */
}

.completed-tab {
  background-color: #28a745; /* Success color for "Completed" */
}

.rejected-tab {
  background-color: #dc3545; /* Danger color for "Rejected" */
}
.red-button {
  background-color: red !important;
  color: white !important; /* Set the text color for better visibility */
}

/* form checkbox for todo list */
.form-check-input{
  width:1.5em!important;
  height:1.5em!important;
  margin-top: 0px!important;
}
.icon {
  transition: transform 0.2s;
}

.icon:hover {
  transform: scale(1.2);
}

/* .card-animated {
  transition: background-color 0.2s ease;
 
}
.card-animated:hover {
  transform: scale(1.05); 
  transition: transform 0.3s ease; 
}

.card-animated:hover {
  background-color: #f0f0f0; 
} */

/* Button Visibility */
/* .card-buttons {
  opacity: 0; 
  transition: opacity 0.3s ease;
}

.card-animated:hover .card-buttons {
  opacity: 1; 
} */



/* for card */
/* .card-animation {
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  height: auto;
  opacity: 1;
}

.card-hidden {
  height: 0;
  opacity: 0;
  overflow: hidden;
} */

/* active button in to do list */
.active {
  background-color: #47AB64; /* Change to the desired color for active buttons */
  color: #fff; /* Change to the desired text color for active buttons */
  /* Add any other styling for active buttons here */
}

/* to do list card */
.card {
  transition: all 0.3s ; 
  border:none !important;
}

/* .card:hover{
  background-color: #f0f0f0; 
}
.description {
  height:100px;
  max-height: 100px; 
}
.title{
  height: 25px;
  max-height: 25px;
}

/* .card-hovered .description {
  max-height: 1000px; 
  transition: all 0.3s ;
  cursor: pointer; 
} */











