.approvalstatus {
  display: flex;
  align-items: center;
  justify-content:center;
  gap:10px;
  /* min-width:150px; */
}

button.approval-btn {
    width: auto;
    color: white; 
    font-size: 15px;
    font-weight: 600;
    background-color: #59AC59;
    border: unset;
    border-radius: 50px;
    height: 27px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.approval-btn:hover{
   background-color: #61c661;
   color:white;
   transition: all 0.3s;
}

span.check-arrow {
    margin-right: 10px;
    background-color: white;
    width: 24px;
    /* height: 24px; */
    border-radius: 50%;
    padding: 0px;
}

button.reject-btn {
    width: auto;
    color:white;
    font-size: 15px;
    font-weight: 600;
    background-color: #F85253;
    border: unset;
    border-radius: 50px;
     height: 27px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.reject-btn:hover{
  background-color: #e94949;
  transition: all 0.3s;
  color:white
}

.action-wrapper{
    display: flex;
    align-items: center;
    gap:10px;
  }

.action-wrapper-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:10px;
    height: 100%;
  }